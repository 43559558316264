<template>
	<v-sheet v- flat color="lightest" class="pa-5 mw-survey rounded-lg" v-if="survey">
		<survey-inner :id="id" :disable="true"></survey-inner>
	</v-sheet>
</template>

<script>
import SurveyInner from "@c/survey/render/Survey.vue";
export default {
	name: "DefaultConsent",
	components: {
		SurveyInner,
	},
	data: () => {
		return {
			id: "MiJOHPjyjNK9MywCWPDB",
		};
	},
    computed: {
        survey(){
            return this.$store.state.surveys.data[this.id]
        }
    }, 
    created() {
        this.$store.dispatch("surveys/fetchById", this.id )
    }
};
</script>
